import { createAction } from '@reduxjs/toolkit';
import {
  IGetVouchersRequestFiltersParams,
  IVouchersNormalizedData,
  VoucherID,
} from 'arcadia-common-fe';
import {
  IDashboardStatisticsResponseBody,
  IPostVoucherRequestBody,
  IVoucherDialogFormState,
  IVouchersRevokeRequestData,
  IVouchersRevokeDialog,
} from '../types';

export const postVoucher = createAction<IPostVoucherRequestBody>('DASHBOARD/POST_VOUCHER');

export const getStatistics = createAction('DASHBOARD/GET_STATISTICS');
export const getStatisticsSuccess = createAction<IDashboardStatisticsResponseBody>('DASHBOARD/GET_STATISTICS/SUCCESS');
export const getStatisticsError = createAction('DASHBOARD/GET_STATISTICS/ERROR');

export const getVouchers = createAction<IGetVouchersRequestFiltersParams>('DASHBOARD/GET_VOUCHERS');
export const getVouchersSuccess = createAction<IVouchersNormalizedData>('DASHBOARD/GET_VOUCHERS/SUCCESS');
export const getVouchersError = createAction('DASHBOARD/GET_VOUCHERS/ERROR');

export const exportVouchers = createAction<IGetVouchersRequestFiltersParams>('DASHBOARD/VOUCHERS_EXPORT');
export const exportVouchersSuccess = createAction('DASHBOARD/VOUCHERS_EXPORT/SUCCESS');
export const exportVouchersError = createAction('DASHBOARD/VOUCHERS_EXPORT/ERROR');

export const setSelectedVouchers = createAction<VoucherID[]>('DASHBOARD/SET_SELECTED_VOUCHERS');

export const setVoucherDialogForm = createAction<Partial<IVoucherDialogFormState> | undefined>('DASHBOARD/SET_VOUCHER_DIALOG_FORM');
export const mergeVoucherDialogForm = createAction<Partial<IVoucherDialogFormState>>('DASHBOARD/MERGE_VOUCHER_DIALOG_FORM');

export const vouchersRevoke = createAction<IVouchersRevokeRequestData>('DASHBOARD/VOUCHERS_REVOKE');
export const setVouchersRevokeDialog = createAction<Partial<IVouchersRevokeDialog> | undefined>('DASHBOARD/SET_VOUCHERS_REVOKE_DIALOG');
export const mergeVouchersRevokeDialog = createAction<Partial<IVouchersRevokeDialog>>(
  'DASHBOARD/MERGE_VOUCHERS_REVOKE_DIALOG',
);
