import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import _uniqBy from 'lodash/uniqBy';
import { ISelectOptions } from 'arcadia-common-fe';
import {
  getEntityData, getEntityDataSuccess, getEntityDataError,
} from './actions';
import { handleError } from '../../services/sagasErrorHandler';
import { EntityType, IGroup, IGetEntityRequestBody, IBrandData } from './types';
import { getGroupsRequest, getBrandsRequest } from './api';

const getEntitiesByTypeRequestsMap: {[key in EntityType]: () => any} = {
  * [EntityType.groupName]() {
    const { data } = yield call(getGroupsRequest);

    return data.groups.map(({ name }: IGroup) => ({ value: name, label: name })) || [];
  },
  * [EntityType.brandName]() {
    const { data } = yield call(getBrandsRequest);

    return data.brands.map(({ name }: IBrandData) => ({ value: name, label: name }));
  },
};

function* handleGetEntityDataByType(payload: IGetEntityRequestBody): any {
  return yield call(getEntitiesByTypeRequestsMap[payload.entityType]);
}

export function* handleGetEntityData({ payload }: ReturnType<typeof getEntityData>) {
  try {
    const result: ISelectOptions = yield call(handleGetEntityDataByType, payload);
    const uniqueOptions = _uniqBy(result, (option) => option.value);

    yield put(getEntityDataSuccess({ options: uniqueOptions, entityType: payload.entityType }));
  } catch (error: any) {
    yield put(getEntityDataError({ entityType: payload.entityType }));
    yield handleError(error);
  }
}

export function* selectEntityByTypeSagas(): any {
  yield all([
    yield takeEvery(
      getEntityData,
      handleGetEntityData,
    ),
  ]);
}
