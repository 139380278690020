import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import papaparse from 'papaparse';
import queryString from 'query-string';
import {
  getDateTimeFormatted,
  voucherStatusLabelMap,
  saveStringAsFile,
  IGetVouchersRequestFiltersParams,
  IGetVouchersResponseBody,
  IVouchers,
} from 'arcadia-common-fe';
import { toast } from 'react-toastify';
import { normalize, schema } from 'normalizr';
import {
  getStatistics,
  getStatisticsSuccess,
  getStatisticsError,
  getVouchers,
  getVouchersError,
  getVouchersSuccess,
  exportVouchers,
  exportVouchersSuccess,
  exportVouchersError,
  postVoucher,
  mergeVoucherDialogForm,
  setVoucherDialogForm,
  vouchersRevoke,
  mergeVouchersRevokeDialog,
  setVouchersRevokeDialog,
} from './actions';
import {
  getStatisticsRequest,
  getVouchersRequest,
  postVoucherRequest,
  vouchersRevokeRequest,
} from '../api';
import { handleError } from '../../../services/sagasErrorHandler';

const vouchersSchema = new schema.Entity('vouchers');
const vouchersListSchema = new schema.Array(vouchersSchema);

function* handleGetVouchersRequest(requestParams: IGetVouchersRequestFiltersParams) {
  const { data } = yield call(getVouchersRequest, requestParams);
  const { result: vouchersIds, entities } = normalize<IVouchers>(data.vouchers, vouchersListSchema);

  return {
    ids: vouchersIds,
    entities: entities.vouchers,
    total: data.total,
  };
}

function* handleRefreshVouchers() {
  yield put(getVouchers(queryString.parse(window.location.search)));
}

function* handlePostVoucher({ payload }: ReturnType<typeof postVoucher>) {
  try {
    yield put(mergeVoucherDialogForm({
      isLoading: true,
    }));
    yield call(postVoucherRequest, payload);
    yield put(setVoucherDialogForm());
    yield call(toast.success, 'The voucher has been successfully created');
    yield handleRefreshVouchers();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeVoucherDialogForm({
      isLoading: false,
    }));
  }
}

export function* handleGetStatistics() {
  try {
    const { data } = yield getStatisticsRequest();

    yield put(getStatisticsSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getStatisticsError());
  }
}

export function* handleGetVouchers({ payload }: ReturnType<typeof getVouchers>): any {
  try {
    const vouchersData = yield handleGetVouchersRequest(payload);

    yield put(getVouchersSuccess(vouchersData));
  } catch (error: any) {
    yield handleError(error);
    yield put(getVouchersError());
  }
}

function* handleExportVouchers({ payload }: ReturnType<typeof exportVouchers>): any {
  try {
    const { vouchers }: IGetVouchersResponseBody = yield handleGetVouchersRequest(payload);

    const preparedData = vouchers.map((voucher) => ({
      Status: voucherStatusLabelMap[voucher.status],
      'Voucher ID': voucher.id,
      'Operator Name': voucher.operatorName,
      'Player CID': voucher.playerCid,
      'Group Name': voucher.groupName,
      'Granted Date': getDateTimeFormatted(voucher.grantedDate),
      'Expiration Date': getDateTimeFormatted(voucher.expirationDate),
      Reason: voucher.revocationReason,
    }));

    const csvString = yield call(papaparse.unparse, preparedData);

    yield call(saveStringAsFile, csvString, 'vouchers.csv');
    yield put(exportVouchersSuccess());
  } catch (error: any) {
    yield handleError(error);
    yield put(exportVouchersError());
  }
}

function* handleVouchersRevoke({ payload }: ReturnType<typeof vouchersRevoke>) {
  try {
    const isMultipleVouchersSelected = payload.ids.length > 1;

    yield put(mergeVouchersRevokeDialog({ isLoading: true }));
    yield call(vouchersRevokeRequest, payload);
    yield call(
      toast.success,
      `The Voucher${isMultipleVouchersSelected ? 's' : ''} with id -
      ${payload.ids.join(', ')} ${isMultipleVouchersSelected ? 'have' : 'has'} been successfully revoke`,
    );
    yield put(setVouchersRevokeDialog());
    yield handleRefreshVouchers();
  } catch (error: any) {
    yield handleError(error);
  } finally {
    yield put(mergeVouchersRevokeDialog({ isLoading: false }));
  }
}

export function* dashboardSagas(): any {
  yield all([
    yield takeLatest(
      postVoucher,
      handlePostVoucher,
    ),
    yield takeLatest(
      getStatistics,
      handleGetStatistics,
    ),
    yield takeLatest(
      getVouchers,
      handleGetVouchers,
    ),
    yield takeLatest(
      exportVouchers,
      handleExportVouchers,
    ),
    yield takeLatest(
      vouchersRevoke,
      handleVouchersRevoke,
    ),
  ]);
}
