import { IGetBrandsResponseBody, IGetGroupsResponseBody } from './types';
import { getUrl, makeRequest } from '../../services/api';

export const getGroupsRequest = () => {
  const url = getUrl('/voucherPortal/groupNames');

  return makeRequest<IGetGroupsResponseBody>(url);
};

export const getBrandsRequest = () => {
  const url = getUrl('/brands/names');

  return makeRequest<IGetBrandsResponseBody>(url);
};
